import React from 'react';
import { graphql } from 'gatsby';

import PageHero from '../../components/page-hero';
import Container from '../../components/container';
import Column from '../../components/column';
import Cta from '../../components/cta';
import Heading from '../../components/heading';
import Layout from '../../components/layout';
import Row from '../../components/row';
import { Section } from '../../components/section';
import { Iframe, IframeContainer } from '../../components/iframe';

const PinUp = ({ data: { heroImg } }) => (
  <Layout>
    <PageHero img={heroImg} videoId="435790835">
      <Heading as="h1">Pin-Up</Heading>
      <Cta to="#show-more">show more</Cta>
    </PageHero>
    <Section id="show-more">
      <Container>
        <Row>
          <Column offset="2">
            <Heading as="h2">Pin-up at Polish Boogie Festival</Heading>
          </Column>
        </Row>
        <Row>
          <Column offset="2">
            <p>
              Half of the last century was plagued by World War II. Long years and separation from loved ones lowered the morale of fighting soldiers. They were encouraged by the smiling women who looked at them from the posters on the walls. This is how Pin-Up started, which literally means "Pin it on".
            </p>
          </Column>
        </Row>
        <Row>
          <Column offset="2">
            <p>
              Recent decades have proved to be a renaissance of Pin-Up. However, it does not come back to support the fighting men, but to unite and make women happy! In Pin-Up we find female power, complexes disappear and the beauty of female friendships, mutual support and learning from each other are revealed. The stereotypes that a beautiful woman cannot be wise are broken. Among the world's Pin-Ups we find doctors, airplane pilots, psychologists, policewomen, and even ladies who serve in the navy! Pin-Up is an explosion of awareness, femininity and ties that bind us together. No matter what dimensions you are, how old you are, where you come from! Every woman will find a place for herself in the Pin-Up’s community!
            </p>
          </Column>
        </Row>
        <Row justify="center">
          <IframeContainer>
            <Iframe title="vimeo-player" src="https://player.vimeo.com/video/435790835" frameBorder="0" allowFullScreen />
          </IframeContainer>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default PinUp;

export const PinUpQuery = graphql`
  query PinUpQuery {
    heroImg: file(relativePath: { eq: "pin-up.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    image1: file(relativePath: { eq: "warsztaty-thumb.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 368, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    image2: file(relativePath: { eq: "pokaz-thumb.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 368, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "konkurs-thumb.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 368, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`;
