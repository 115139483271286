import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import Img from 'gatsby-image';

import Container from './container';
import Column from './column';
import Row from './row';
import { SectionBase } from './section';
import { PlyrLoadable } from './loadable-plyr';

const Hero = styled(SectionBase)`
  position: relative;

  ${up('sm')} {
    padding: 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 350px;
    background-size: contain;
    background-image: url(/images/hero-bg.svg);
    background-repeat: no-repeat;
    /* background-position-x: 100%; */
    transform: rotate(-180deg);
    /* transform: translateY(-50%); */
  }

  ${up('lg')} {
    height: calc(100vh - 64px);
    padding: 0;
  }
`;

const HeroContainer = styled(Container)`
  ${up('lg')} {
    height: 100%;
  }
`;

const HeroRow = styled(Row)`
  display: flex;
  flex-direction: column-reverse;

  ${up('lg')} {
    flex-direction: row;
  }
`;

const ImgColumn = styled(Column)`
  ${up('md')} {
    display: block;
  }
`;

const VideoColumn = styled(Column)`
  ${up('md')} {
    flex-basis: 40%;
  }
`;

const NonStretchedImage = (props) => {
  let normalizedProps = props;
  const { fluid } = props;

  if (fluid && fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        overflow: 'hidden',
        minWidth: '100%',
        maxWidth: props.fluid.presentationWidth,
        maxHeight: '100vh',
        margin: '0 auto', // Used to center the image
      },
    };
  }

  return (<Img {...normalizedProps} />);
};

export default ({ children, img, videoId }) => (
  <Hero>
    <HeroContainer>
      <HeroRow align="center">
        <Column>
          { children }
        </Column>
        {
          videoId && (
            <VideoColumn className="hero-video">
              <PlyrLoadable
                options={{
                  autoplay: true,
                  controls: [],
                  loop: { active: true },
                  volume: 0,
                  storage: { enabled: false },
                }}
                source={{
                  type: 'video',
                  sources: [{
                    src: videoId,
                    provider: 'vimeo',
                  }],
                }}
              />
            </VideoColumn>
          )
        }
        {
          img && !videoId && (
            <ImgColumn>
              <NonStretchedImage fluid={img.childImageSharp.fluid} />
            </ImgColumn>
          )
        }
      </HeroRow>
    </HeroContainer>
  </Hero>
);
